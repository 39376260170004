import { Content } from "@/ui";
import { createTitle } from "@/functions/createTitle";
import { mini_carousel_helper } from "@/helpers/mini_carousel_helper";
import { getRoute, getRouteObject } from "@/functions/getRoute";
import { simple_pages_helper } from "@/helpers/simple_pages_helper";
import { jobs_helper } from "@/helpers/jobs_helper";
import sn from "@/snippets";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({}) {
  const route = getRouteObject("featuredEmployers");
  const page = simple_pages_helper.find(route.id, "id");
  const children = simple_pages_helper.fetch({ parent: route.id });

  return {
    props: {
      meta: {
        title: createTitle("Home"),
      },
      content: [
        {
          component: "Hero",
          props: {
            img: { path: "page.home.component.Hero.img" },
          },
        },
        { component: "FeaturedEmployers",
        props: {
          items: children,
        }
       },
       sn.tileGroups({ page: { tile_groups: " Job Type" } }),
        {
          component: "MiniCarousel",
          props: {
            title: { path: "page.home.component.MiniCarousel.title" },
            items: mini_carousel_helper.fetch({
              filter: (i) => i.tags.toLowerCase().includes("home") || i.tags.includes("*"),
            }),
          },
        },
        {
          component: "LatestJobs",
          props: {
            className: "bg-primary py-5",
            title: { path: "page.home.component.LatestJobs.title", placeholder: "Latest Jobs" },
            items: jobs_helper.fetch(),
            visibleCount: 4,
            filters: true,
          },
        },
        {
          component: "LatestBlogs",
          props: {
            title: { path: "page.home.component.LatestBlogs.title" },
          },
        },

      
      ],
    },
  };
}
